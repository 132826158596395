import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from 'components/Navbars/ITCreativeNav';
import Footer from 'components/Saas/Footer';
import Aboutw from 'components/Style2/About/About';
import SideMenu from 'components/ITCreative/SideMenu';

const WhoWeAre = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav style="1" /> */}
      <Navbar navbarRef={navbarRef} />
      <SideMenu />
      <main className="about-page style-5">       
       
        <Aboutw />        
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Mutual Trust MFBank</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
      
    </>
  )
}

export default WhoWeAre;